import React, { useState } from "react"
import { graphql } from "gatsby"
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import classNames from 'classnames'

import Loading from '../../components/Loading'
import CustomInput from '../../components/CustomInput'
import axios from 'axios';
import { navigate } from "gatsby";
import Layout from "../../components/Layout"
import SEO from "../../components/seo"

import img from '/static/assets/linda-stefan.png'
import grid from '../avveckla-aktiebolag/assets/images/gridRepeatGrey.svg'
import * as styles from './Contact.module.scss'

export const pageQuery = graphql`
  query ContactQuery($id: String!){
		markdownRemark(id: { eq: $id }) {
      id
			html
			excerpt(pruneLength: 140)
      frontmatter {
        title
        metaTitle
        formTitle
        contactUs
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`
// =================== Validation schema ===================
const schema = yup.object().shape({
  name: yup.string().required('Namn krävs'),
  email: yup.string().email('Ogiltig e-postadress').required('E-postadress krävs'),
  confirmationEmail: yup.string()
    .oneOf([yup.ref('email'), null], 'E-post måste matcha').required('Bekräfta e-post krävs'),
  message: yup.string().required('Meddelande krävs')
});

const Contact = ({ data }) => {

  const { markdownRemark, site } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark
  const [loading, setLoading] = useState(false);
  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema)
  });

  const onSubmitContactForm = (data) => {
    setLoading(true);
    axios.post(`${process.env.GATSBY_API_URL}/contact-us`, data)
      .then(() => {
        navigate('/tack');
        setLoading(false);
      }).catch(err =>
        console.log(err))
  }

  return (
    <Layout className="contact-page" hideBottomPage={true}>
      <SEO
        title={frontmatter.metaTitle ? frontmatter.metaTitle : frontmatter.title}
        description={frontmatter.title + " " + site.siteMetadata.title} />
      <div className={styles.mapContainer}>
        <iframe
          title="CBS"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2030.813671610265!2d18.352795151383678!3d59.40282111208033!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x465f86be3891d39b%3A0x63fe9f2b9e6e3d9e!2sCitadellet%20Bolagsservice%20AB!5e0!3m2!1sen!2smk!4v1614087887071!5m2!1sen!2smk"
          width="100%"
          height="450"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy" />
      </div>
      <div className={styles.background}>
        <div className={styles.contactContainer}>
          <div className={styles.contactItem}>
            <div>
              <img src={grid} alt="grid" className={styles.gridSvg} />
            </div>
            <h2 className={styles.title}>{frontmatter.title}</h2>
            <div className={styles.borderLine} />
            <div className={styles.body} dangerouslySetInnerHTML={{ __html: html }} />
            <div className={styles.imageWrapper}>
              <img src={img} alt="img" className={styles.contactImg} />
            </div>
          </div>

          <div className={styles.contactItem}>
            <h2 className={styles.title}>{frontmatter.formTitle}</h2>
            <div className={styles.borderLine} />
            <form
              className={styles.contactForm}
              onSubmit={handleSubmit(onSubmitContactForm)}
              data-netlify="true"
              data-netlify-honeypot="bot-field">
              <div className={styles.inputsContainer}>
                <CustomInput
                  name="name"
                  label="Namn *"
                  type="text"
                  register={register}
                  error={errors.name?.message} />
                <div className={styles.spaceBetweenColumns} />
                <CustomInput
                  name="email"
                  label="E-postadress *"
                  type="email"
                  register={register}
                  error={errors.email?.message} />
              </div>
              <div className={classNames(styles.inputsContainer, styles.emailInput)}>
                <CustomInput
                  name="confirmationEmail"
                  label="Bekräfta e-post *"
                  type="email"
                  register={register}
                  error={errors.confirmationEmail?.message} />
                <span className={styles.inputDescription}>
                  Ange din epostadress igen.
                </span>
              </div>
              <div className={styles.inputsContainer}>
                <CustomInput
                  name="message"
                  label="Meddelande *"
                  type="textarea"
                  register={register}
                  error={errors.message?.message} />
              </div>
              <div>
                <button type="submit" className={styles.CustomButton} disabled={loading}>
                  {loading ? <Loading /> : 'skicka meddelande'}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Contact
