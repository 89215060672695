import React from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import * as styles from './customInput.module.scss';

const CustomInput = ({ name, label, type, register, error }) => {
    return (
        <div className={styles.container}>
            <div className={classNames(styles.inputContainer, type === 'textarea' && styles.textareaContainer)}>
                {type === 'textarea' ? (
                    <textarea
                        placeholder=" "
                        name={name}
                        type={type}
                        className={classNames(styles.outlinedInput, styles.textareaContainer, error !== undefined && styles.notValid)}
                        ref={register}
                    />
                ) :
                    <input
                        placeholder=" "
                        name={name}
                        type={type}
                        ref={register}
                        className={classNames(styles.outlinedInput, error !== undefined && styles.notValid)} />
                }
                <label className={classNames(styles.outlinedLabel, type === 'textarea' && styles.isTextarea, error !== undefined && styles.notValid )}>{label}</label>
            </div>
            <div className={styles.errorMsg}>
                {error}
            </div>
        </div>
    )
}

CustomInput.propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    type: PropTypes.string,
    register: PropTypes.func,
    error: PropTypes.string
}

export default CustomInput;