// extracted by mini-css-extract-plugin
export var mapContainer = "Contact-module--mapContainer--3iw8L";
export var background = "Contact-module--background--Dvzht";
export var contactContainer = "Contact-module--contactContainer--2Pp1f";
export var gridSvg = "Contact-module--gridSvg--2zBMN";
export var contactItem = "Contact-module--contactItem--wnPIe";
export var title = "Contact-module--title--3lNWT";
export var borderLine = "Contact-module--borderLine--glZBw";
export var imageWrapper = "Contact-module--imageWrapper--bVM5T";
export var contactImg = "Contact-module--contactImg--2DysG";
export var body = "Contact-module--body--ETzBn";
export var formTitle = "Contact-module--formTitle--Bho67";
export var contactForm = "Contact-module--contactForm--m89C9";
export var CustomButton = "Contact-module--CustomButton--2Np_E";
export var inputsContainer = "Contact-module--inputsContainer--14xFR";
export var spaceBetweenColumns = "Contact-module--spaceBetweenColumns--3lsle";
export var inputDescription = "Contact-module--inputDescription--1AKtl";
export var emailInput = "Contact-module--emailInput--3A-L0";